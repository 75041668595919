@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600&display=swap");

@import "variables";
@import "~normalize.css/normalize";

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Epilogue", sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
p,
a {
  transition: all 100ms ease-in-out;
  color: $black-primary;
  margin: 0;
}

a:hover {
  color: $black-secondary !important;
}

h1 {
  font-size: 80px;
  font-weight: 600;
  line-height: 90px;

  @media (max-width: 480px) {
    font-size: 64px;
  }
}

h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
}

h3 {
  font-size: 27px;
  font-weight: 600;
  line-height: 42px;
}

h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

p {
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
}

.button {
  padding: 25px 82px;
  border-radius: 8px;
  background: $black-primary;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: $white;
  border: none;
  outline: none;
  cursor: pointer;

  transition: all 100ms ease-in-out;

  &:hover {
    background-color: $black-secondary;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.error-message {
  color: #ff0000;
  margin-top: 10px;
}

input {
  padding: 21px 30px;
  border-radius: 8px;
  background: $bg-input;
  border: none;
  outline: none;
}

textarea {
  resize: none;
  padding: 21px 30px;
  border-radius: 8px;
  background: $bg-input;
  border: none;
  outline: none;
}

.container {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;

  @media (max-width: 1160px) {
    max-width: 90%;
  }
}

.nav-mobile {
  display: flex;
  flex-direction: column;
  gap: 28px;
  border-bottom: 1px solid #d9d9d9;
  padding: 40px 0px 49px 0px;
  text-align: center;
  position: sticky;
  top: 80px;
  background-color: $white;
  z-index: 100;

  & > a {
    text-decoration: none;
    padding: 24px;
    font-size: 17px;
    line-height: 28px;
  }

  @media (max-width: 1160px) {
    top: 72px;
  }
}

header {
  display: flex;
  justify-content: space-between;
  padding: 24px 50px;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 100;
  border-bottom: 1px solid #d9d9d9;

  .navigation {
    display: flex;
    align-items: center;
    gap: 48px;

    & > a {
      text-decoration: none;
      font-size: 17px;
      line-height: 28px;

      transition: all 100ms ease-in-out;

      &:hover {
        color: $black-secondary;
      }
    }
  }

  .navigation-mobile {
    display: none;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
  }

  @media (max-width: 1160px) {
    padding: 20px 30px;

    .navigation {
      display: none;
    }

    .navigation-mobile {
      display: block;
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;

  .welcome {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
    padding: 100px 0px;

    &__description {
      width: 100%;
      max-width: 557px;
      display: flex;
      flex-direction: column;
      gap: 40px;

      & > div:first-child {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }

      button {
        align-self: flex-start;
      }
    }

    @media (max-width: 1160px) {
      flex-direction: column;
      text-align: center;
      gap: 60px;
      padding: 50px 0px 60px 0px;

      .welcome {
        gap: 60px;
        padding: 100px 0px;
      }

      &__description {
        & > div:first-child {
          gap: 20px;
        }
      }
    }
  }

  .work {
    width: 100%;
    padding: 180px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    position: relative;

    &__section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .stage {
        width: 100%;
        max-width: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 56px;

        & > div {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }

      .stage:last-child {
        align-self: flex-start;
      }
    }

    &__arrow-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media (max-width: 1160px) {
      width: auto;
      padding: 150px 0px 100px 0px;
      gap: 80px;

      h2 {
        display: none;
      }

      &__section {
        flex-direction: column;
        justify-content: center;
        gap: 90px;

        .stage:last-child {
          align-self: auto;
        }
      }

      &__arrow {
        rotate: 90deg;
        width: 40%;
      }

      &__arrow-center {
        position: initial;
        transform: initial;
        rotate: 294deg;
        width: 40%;
      }
    }

    @media (max-width: 440px) {
      &__arrow {
        width: 60%;
      }

      &__arrow-center {
        width: 60%;
      }
    }
  }

  .projects {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    &__container {
      display: flex;
      gap: 67px;
    }

    &__section {
      width: 100%;
      max-width: 490px;
      display: flex;
      flex-direction: column;
      gap: 25px;

      img {
        align-self: flex-start;
      }

      a {
        text-decoration: none;
      }
    }

    @media (max-width: 1160px) {
      padding: 70px 0px;

      border-top: 2px solid rgba(0, 0, 0, 0.2);

      h2 {
        text-align: center;
      }

      &__container {
        flex-direction: column;
      }
    }
  }

  .executives {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;

    h2 {
      text-align: center;
    }

    &__container {
      display: flex;
      gap: 67px;
    }

    &__section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 200px;

      div {
        display: flex;
        flex-direction: column;

        a:first-child {
          font-weight: bold;
          text-decoration: underline;
        }

        a:last-child {
          color: #585858;
          font-size: 16px;
          font-weight: 600;
          line-height: 27px;
          text-decoration: none;
        }
      }
    }

    @media (max-width: 1160px) {
      padding: 0px;

      &__container {
        flex-direction: column;
      }

      &__section {
        max-width: 300px;
      }
    }
  }
}

footer {
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  padding: 70px 0px;

  .info {
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 100%;
    max-width: 360px;
    align-self: flex-start;

    div:first-child {
      display: flex;
      flex-direction: column;
      gap: 40px;

      a {
        text-decoration: none;
      }
    }

    div:last-child {
      display: flex;
      align-items: flex-start;
      gap: 24px;

      a:first-child {
        color: #585858;
        font-size: 24px;
        font-weight: 600;
        line-height: 42px;
        text-decoration: none;
      }
    }
  }

  .contact-form {
    width: 100%;
    max-width: 530px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 40px;

    & > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    button {
      align-self: flex-start;
    }

    &__field {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 1160px) {
    border: none;
    flex-direction: column;
    gap: 80px;

    .contact-form {
      width: 100%;
      align-self: center;
    }

    .info {
      align-self: auto;
    }
  }
}
